<template>
  <div class="router">
    <div class="box100">
      <div class="router_content" id="kefuMap"></div>
      <div class="" :class="MapbgShow?'mapBg': ''"></div>
      <div class="main_left chaBJ">
        <p><span class="cbjb Bjs1"></span>火警信息</p>
        <p><span class="cbjb Bjs2"></span>故障</p>
        <p><span class="cbjb Bjs3"></span>隐患</p>
        <p><span class="cbjb Bjs4"></span>其他</p>
        <p><span class="cbjb Bjs0"></span>正常</p>
      </div>
      <div class="main_right rightBG">
        <div class="rightBox">
          <div class="right_top"><Equana :Refresh="Refresh"></Equana></div>
          <div class="right_alarm">
            <Alarm :Refresh="Refresh" @alarmNews="alarmNews"></Alarm>
          </div>
        </div>
      </div>
    </div>
    <!--  告警详情  -->
    <el-dialog :visible.sync="alarmDetailShow" class="alarmDetailDialog alarmDialog hdBg" width="1200px" :close-on-click-modal="false">
      <AlarmDetail :alarmKey="alarmKey"
                   @dealHandShow="dealHandShow"
                   @assignHandShow="assignHandShow"
                   @superviseHandShow="superviseHandShow"
                   @misreportHandShow="misreportHandShow"
                   @openImg="openImg"
                   @customerDealShow="customerDealShow"
                   @customerTel="customerTel"
                   @busGzDeal="busGzDeal"
                   @ysDealShow="ysDealShow">
      </AlarmDetail>
    </el-dialog>
    <!-- 告警详情 弹窗 AI放大图片 -->
    <el-dialog title="抓拍图片" :visible.sync="showBigImg" center width="1000px" class="hdBg hdTb" style="z-index: 999;">
      <div class="bigImgBox">
        <div class="sizeBox" @mousewheel.prevent="rollImg">
          <img :src="bigImgUrl" alt="" class="img" ref="imgDiv" :onerror="notImg" @mousedown="ImgMove"/>
        </div>
      </div>
    </el-dialog>

    <!-- 处理 -->
    <el-dialog title="处理" :visible.sync="dealWithShow" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <DealWith :alarmOperate="alarmOperate"
                @dealHandCancel="dealHandCancel"
                @dealHandConfirm="dealHandConfirm">
      </DealWith>
    </el-dialog>

    <!-- 分派 -->
    <el-dialog title="分派" :visible.sync="assignShow" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <Assign :alarmOperate="alarmOperate"
              @assignHandCancel="assignHandCancel"
              @assignHandConfirm="assignHandConfirm">
      </Assign>
    </el-dialog>

    <!-- 督办 -->
    <el-dialog title="督办" :visible.sync="superviseShow" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <Supervise :alarmOperate="alarmOperate"
                 @superHandCancel="superHandCancel"
                 @superHandConfirm="superHandConfirm">
      </Supervise>
    </el-dialog>

    <!-- 告警弹框 -->
    <el-dialog :visible.sync="alarmDialog" center class="alaDialog" width="460px" :modal='false'>
      <div class="topCont">
        <div class="flexCB">
          <div class="flexAC">
            <span class="iconfont iconunit" style="font-size: 20px;"></span><span>{{ messageInfo.companyname }}</span>
          </div>
          <div class="ait_r">{{ messageInfo.reportime }}</div>
        </div>
        <audio controls="controls" :src="sound" ref="audio" autoplay="autoplay" hidden="true"></audio>
        <div class="flexAC" style="margin-top: 15px;">
          <div class="equIl"><span class="iconfont iconequa" :class="'icon' + messageInfo.equtypesvg"></span><p>{{ messageInfo.equname }}</p></div>
          <div class="equIm" style="margin: 10px 25px 0px">
            <p class="equi1"><span class="iconfont iconliebiao"></span><span>{{ messageInfo.content }}</span></p>
            <p class="equi"><span class="iconfont iconlocation"></span><span>{{ messageInfo.equadderss }}</span></p>
          </div>
          <div class="equIr" style="margin-top: 10px;">
            <p class="equi1"><span class="iconfont iconrenshu"></span><span>{{ messageInfo.securityadmin }}</span></p>
            <p class="equi"><span class="iconfont icondianhua"></span><span>{{ messageInfo.securityphone }}</span></p>
          </div>
        </div>
      </div>
      <div class="fitCont flexCB">
        <div class="alfi2" @click="dealHandShow(messageInfo)">处理</div>
        <div class="alfi2" @click="assignHandShow(messageInfo)">分派</div>
        <div class="alfi2" @click="misreportHandShow(messageInfo)">误报</div>
      </div>
    </el-dialog>

    <!--  客服-处理-弹框  -->
    <el-dialog title="处理" :visible.sync="customerDealWith" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <CusDealWith :alarmInfo="alarmInfo" @dealClose="dealClose" @busDealSuccess="busDealSuccess"></CusDealWith>
    </el-dialog>

    <!-- 客服-延时处理-弹框 -->
    <el-dialog title="延时处理" :visible.sync="ysDealDialog" class="dealDialog hdBg hdTb" width="600px" :close-on-click-modal="false">
      <Delayed :alarmInfo="alarmInfo" @delayedClose="delayedClose" @delayedSuccess="delayedSuccess"></Delayed>
    </el-dialog>
  </div>
</template>

<script>
import sounds from '@/assets/alarm.mp3'
import Equana from '@/components/home/equana'
import Alarm from '@/components/home/alarm'
import AlarmDetail from '@/components/home/alarmDetail'
import DealWith from '@/components/home/dealWith'
import Assign from '@/components/home/assign'
import Supervise from '@/components/home/supervise'
import CusDealWith from '@/components/customer/dealWith.vue'
import Delayed from '@/components/customer/delayed.vue'
import { alarmReport } from '@/http/api/alarm'
import { customerUnit } from '@/http/api/home'
export default {
  name: 'customerCenter',
  props: ['messageInfo'],
  components: {
    Equana,
    Alarm, // 告警列表
    AlarmDetail, // 告警详情
    DealWith, // 处理
    Assign, // 分派
    Supervise, // 督办
    CusDealWith, // 客服处理
    Delayed // 客服延迟处理
  },
  data () {
    return {
      sound: sounds,
      map: '',
      lng: '',
      lat: '',
      Refresh: false, // 刷新
      alarmDetailShow: false,
      alarmKey: '',
      alarmOperate: '',
      dealWithShow: false,
      assignShow: false,
      superviseShow: false,
      unitList: [],
      markers: '',
      mapUnitText: '',
      alarmDialog: false,
      alarmMarker: '',
      MapbgShow: true,
      showBigImg: false,
      notImg:  'this.src="'+ require('../../assets/img/imgError.png') +'"',
      bigImgUrl: '',
      customerDealWith: false,
      alarmInfo: '',
      ysDealDialog: false
    }
  },
  watch: {
    messageInfo (val) {
      this.Refresh = !this.Refresh
      // this.alarmDialog = true
      // this.messageInfo = val
      // console.log(this.messageInfo)
      // this.$nextTick(() => {
      //   this.audioPlay()
      //   this.getUnitList()
      // })
    }
  },
  mounted () {
    this.lng = JSON.parse(window.localStorage.getItem('loginInfo')).companylong
    this.lat = JSON.parse(window.localStorage.getItem('loginInfo')).companylat
    this.initMap()
  },
  methods: {
    audioPlay () {
      this.$refs.audio.play()
    },
    initMap () {
      var that = this
      /* 高德地图【地图初渲染】 */
      that.map = new AMap.Map('kefuMap', {
        center: [that.lng, that.lat],
        expandZoomRange: true,
        zoom: 10,
        pitch: 0, // 地图俯仰角度，有效范围 0 度- 83 度
        rotation: 0,
        viewMode: '3D', // 地图模式
        // mapStyle: 'amap://styles/0362e2abe7952f5c8c847c02ae08e15c',
        // mapStyle: 'amap://styles/516ae96c8fcd9801fccf33854c7a6c33',
        buildingAnimation: true
      })
      that.map.setMapStyle('amap://styles/grey')

      /* 天地图【地图初渲染】 */
      // that.map = new T.Map('kefuMap')
      // that.map.centerAndZoom(new T.LngLat(that.lng, that.lat), 10) // 设置地图中心点
      // that.map.setStyle('indigo') // 设置地图主题
      setTimeout(() => {
        this.MapbgShow = false
      }, 400)
      this.getUnitList()
    },
    /* 客服管辖单位 */
    getUnitList () {
      customerUnit().then(res => {
        if (res.status === '1') {
          this.unitList = res.data
          this.drawMarker()
        }
      })
    },
    /* 单位标记 */
    drawMarker () {
      var that = this
      that.map.remove(that.markers)
      that.unitList.forEach(item => {
        var img = ''
        if (item.alarmtype === 0) {
          img = require('../../assets/img/unit0.png')
        } else if (item.alarmtype === 1) {
          img = require('../../assets/img/unit1.png')
        } else if (item.alarmtype === 2) {
          img = require('../../assets/img/unit2.png')
        } else if (item.alarmtype === 3) {
          img = require('../../assets/img/unit3.png')
        } else if (item.alarmtype === 4) {
          img = require('../../assets/img/unit4.png')
        } else {
          img = require('../../assets/img/unit0.png')
        }
        /*【天地图】创建图片对象 */
        // var icon = new T.Icon({
        //   iconUrl: img,
        //   iconSize: new T.Point(56, 62),
        //   iconAnchor: new T.Point(-28, -32)
        // })
        // // 【天地图】向地图上添加自定义标注
        // that.markers = new T.Marker(new T.LngLat(item.companylong, item.companylat), { icon: icon, zIndexOffset: 1 })
        // that.map.addOverLay(that.markers)
        // marClickHandler(that.markers, item)

        /* 高德地图【单位标记】 */
        that.markers = new AMap.Marker({
          position: [item.companylong, item.companylat],
          icon: new AMap.Icon({
            image: img,
            size: new AMap.Size(56, 62), // 图标所处区域大小
            imageSize: new AMap.Size(56, 62) // 图标大小
          }),
          offset: new AMap.Pixel(-28, -62)
        })
        that.map.add(that.markers)
        marClickHandler(that.markers, item)
      })
      function marClickHandler (marker, item) {
        /* 点击单位标记 */
        marker.on('click', function (e) {
          const obj = { path: 'connect', ac: 'connect' }
          window.localStorage.setItem('tabActive', JSON.stringify(obj))
          that.$router.push({ path: '/connect', query: { data: item } })
        })
        /* 鼠标经过[单位标记] */
        marker.on('mouseover', function (e) {
          /* 天地图 */
          // var point = e.lnglat
          // marker = new T.Marker(point) // 创建标注
          // that.mapUnitText = new T.InfoWindow(
          //   '<div class="mapUnitName">'+ item.companyname +'</div>',
          //   { offset:new T.Point(60, 40) }
          // ) // 创建信息窗口对象
          // that.map.openInfoWindow(that.mapUnitText, point) //开启信息窗口

          /*  [old]高德地图  */
          that.mapUnitText = new AMap.Text({
            text: item.companyname,
            position: [item.companylong, item.companylat],
            anchor: 'bottom-center', // 设置文本标记锚点
            draggable: false,
            cursor: 'pointer',
            style: {
              'padding': '5px 10px',
              'border-radius': '5px',
              'background-color': 'rgba(21,31,31,0.8)',
              'border-color': '#36999d',
              'text-align': 'center',
              'font-size': '14px',
              'color': '#36999d'
            },
            offset: new AMap.Pixel(0, -55)
          })
          that.map.add(that.mapUnitText)
        })
        marker.on('mouseout', function (e) {
          that.map.remove(that.mapUnitText)
          // that.map.closeInfoWindow()
        })
      }
    },
    /* 告警详情 */
    alarmNews (res) {
      this.alarmDetailShow = true
      this.alarmKey = res
    },
    // 处理展示
    dealHandShow (res) {
      this.dealWithShow = true
      this.alarmOperate = res
    },
    // 处理关闭
    dealHandCancel () {
      this.dealWithShow = false
    },
    // 处理提交
    dealHandConfirm () {
      this.dealWithShow = false
      this.confirmRefresh()
    },
    // 分派展示
    assignHandShow (res) {
      this.assignShow = true
      this.alarmOperate = res
    },
    // 分派关闭
    assignHandCancel () {
      this.assignShow = false
    },
    // 分派提交
    assignHandConfirm () {
      this.assignShow = false
      this.confirmRefresh()
    },
    // 督办展示
    superviseHandShow (res) {
      this.superviseShow = true
      this.alarmOperate = res
    },
    // 督办关闭
    superHandCancel () {
      this.superviseShow = false
    },
    // 督办提交
    superHandConfirm () {
      this.superviseShow = false
      this.confirmRefresh()
    },
    /* 客服-处理-(火警-隐患)-展示 */
    customerDealShow (res) {
      this.customerDealWith = true
      this.alarmInfo = res
    },
    /* 客服-处理-(火警-隐患)-取消 */
    dealClose () {
      this.customerDealWith = false
    },
    /* 客服-处理-(火警-隐患)-提交 */
    busDealSuccess () {
      this.customerDealWith = false
      this.confirmRefresh()
    },
    /* 客服-电核 */
    customerTel () {
      this.confirmRefresh()
    },
    /* 客服-处理-(故障) */
    busGzDeal () {
      this.confirmRefresh()
    },
    /* 客服-延时处理-(隐患)-展示 */
    ysDealShow (res) {
      this.ysDealDialog = true
      this.alarmInfo = res
    },
    /* 客服-延时处理-(隐患)-取消*/
    delayedClose () {
      this.ysDealDialog = false
    },
    /* 客服-延时处理-(隐患)-提交*/
    delayedSuccess () {
      this.ysDealDialog = false
      this.confirmRefresh()
    },
    /* 处理成功(关闭-刷新) */
    confirmRefresh () {
      this.alarmDetailShow = false
      this.alarmDialog = false
      this.Refresh = !this.Refresh
    },
    // 误报
    misreportHandShow (res) {
      this.confirmRefresh()
      // this.$confirm('点击误报后本条告警信息将会自动标记为误报信息，请确定是否误报?', '误报', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      //   customClass: 'Misinform',
      //   center: true
      // }).then(() => {
      //   alarmReport({ ectpid: res.ectpid }).then(res => {
      //     if (res.status === '1') {
      //       this.$message.success(res.message)
      //       this.alarmDetailShow = false
      //       this.alarmDialog = false
      //       this.Refresh = !this.Refresh
      //     } else {
      //       this.$message.success(res.message)
      //     }
      //   })
      // }).catch(() => {})
    },
    /* Ai摄像 */
    openImg (res) {
      this.showBigImg = true
      this.bigImgUrl = res
      this.$nextTick(() => {
        this.$refs.imgDiv.style.left = '0px'
        this.$refs.imgDiv.style.top = '0px'
        this.$refs.imgDiv.style.zoom = "100%"
      })
    },
    rollImg (event) {
      var zoom = parseInt(this.$refs.imgDiv.style.zoom) || 100
      zoom += event.wheelDelta / 12
      if (zoom >= 80 && zoom <500) {
        this.$refs.imgDiv.style.zoom = zoom + "%"
      }
      return false
    },
    ImgMove (e) {
      e.preventDefault()
      var left = document.querySelector('.sizeBox')
      var img = document.querySelector('.img')
      var x = e.pageX - img.offsetLeft
      var y = e.pageY - img.offsetTop
      left.addEventListener('mousemove', move)
      function move(e) {
        img.style.left = e.pageX - x + 'px'
        img.style.top = e.pageY - y + 'px'
      }
      img.addEventListener('mouseup', function() {
        left.removeEventListener('mousemove', move)
      })
      left.addEventListener('mouseout', function() {
        left.removeEventListener('mousemove', move)
      })
    }
  }
}
</script>

<style scoped>
.main_right{
  width: 540px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  padding: 90px 40px 20px;
  box-sizing: border-box;
  /*z-index: 500;*/
}
.main_left{
  width: 100px;
  height: auto;
  position: absolute;
  left: 40px;
  bottom: 20px;
  font-size: 14px;
  color: #A5B7CC;
}
.chaBJ p{
  margin-top: 12px;
  display: flex;
  align-items: center;
}
.cbjb{
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-right: 10px;
}
.Bjs0{background: #29CB85;}
.Bjs1{background: #FF4C4C;}
.Bjs2{background: #FFAD42;}
.Bjs3{background: #D930EC;}
.Bjs4{background: #3476F3;}
.rightBG{
  background: linear-gradient(270deg, #091026, rgba(21, 35, 54, 0.8));
  box-shadow: -10px 0px 50px 40px rgba(21, 35, 54, 0.8);
}
.rightBox{
  width: 100%;
  height: 100%;
  padding: 20px 0px;
  box-sizing: border-box;
  position: relative;
}
.right_top{
  width: 100%;
  height: 280px;
}
.right_alarm {
  width: 100%;
  height: calc(100% - 280px);
  position: relative;
}
.alaDialog >>> .el-dialog{
  display: flex;
  flex-direction: column;
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  max-height:calc(100% - 30px);
  max-width:calc(100% - 30px);
}
.alaDialog >>> .el-dialog .el-dialog__body{
  flex:1;
  overflow: auto;
}
.alaDialog >>> .el-dialog__header,.alaDialog >>> .el-dialog--center .el-dialog__body{
  padding: 0px;
  background: transparent;

}
.alaDialog >>> .el-dialog__headerbtn{
  display: none;
}
.alaDialog .topCont{
  width: 100%;
  height: 150px;
  padding: 20px;
  font-size: 14px;
  box-sizing: border-box;
  color: #fff;
  background: linear-gradient(135deg, rgba(226, 45, 45, 0.8) 0%, rgba(227, 94, 59, 0.7) 100%);
}
.fitCont{
  width: 100%;
  height: 40px;
  background: rgba(9, 18, 35, 0.9);
}
.alaDialog >>> .equIl{
  text-align: center;
}
.alaDialog >>> .equIl .iconfont{
  font-size: 50px;
}
.alaDialog >>> .equi1,.alaDialog >>> .equi{
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.alaDialog >>> .equi{
  margin-bottom: 0px;
}
.alaDialog >>> .equi1 .iconfont{
  font-size: 18px;
  margin-right: 4px;
}
.alaDialog >>> .equi .iconfont{
  font-size: 17px;
  margin-right: 4px;
}
.alaDialog>>>.alfi2{
  width: 25%;
  height: 20px;
  text-align: center;
  color: #FFAD42;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  border-right: 1px solid rgba(255, 173, 66, 0.3);
}
.alaDialog>>>.alfi2{
  width: 33.3%;
}
.alaDialog>>>.alfi2:last-child,.alaDialog>>>.alfi:last-child{
  border-right: none;
}
.mapBg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #152336;
}
#kefuMap >>> .tdt-label{
  background: transparent;
  padding: 0;
  border: none;
}
#kefuMap >>> .tdt-infowindow-content-wrapper,
#kefuMap >>> .tdt-infowindow-tip{
  background: transparent;
  box-shadow: none;
}
#kefuMap >>> a.tdt-infowindow-close-button,
#kefuMap >>> .tdt-infowindow-tip-container{
  display: none;
}
#kefuMap >>> .mapUnitName{
  padding: 5px 10px;
  border-radius: 5px;
  background: rgba(21,31,31,0.8);
  border-color: #36999d;
  text-align: center;
  font-size: 14px;
  color: #36999d;
  z-index: 100;
}
</style>
